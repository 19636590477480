import React from "react";
import { Radio } from "antd";
import {
  AppstoreOutlined,
  BarsOutlined,
  MessageOutlined,
  ProjectFilled,
  ProjectOutlined,
} from "@ant-design/icons";

const ViewSelector = ({ currentView, onDetailsChange, onViewChange }) => {
  return (
    <div style={{ marginBottom: 20, display: "flex", gap: 10 }}>
      <Radio.Group
        value={currentView}
        onChange={(e) => onViewChange(e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button value="details">
          <BarsOutlined /> Details
        </Radio.Button>
        <Radio.Button value="chat">
          <MessageOutlined /> Chat
        </Radio.Button>
        <Radio.Button value="board">
          <ProjectOutlined /> Board
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};

export default ViewSelector;
