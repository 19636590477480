import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Layout, Menu, Dropdown, Button, Space, FloatButton } from "antd";
import {
  HomeOutlined,
  UserOutlined,
  SettingOutlined,
  MessageOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import "./App.css";
import SidebarMenu from "./components/SidebarMenu";
import Home from "./pages/Home";
import Things from "./pages/Things";
import Thing from "./pages/Thing";
import AddObjectType from "./pages/AddObjectType";
import axios from "axios";
import EditObjectType from "./pages/EditObjectType";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import ProtectedRoute from "./components/ProtectedRoute";
import { App as GoogleAuthApp } from "./components/GoogleAuthProvider";
import CreateTeam from "./pages/CreateTeam";
import JoinTeam from "./pages/JoinTeam";
import UserProfile from "./pages/UserProfile";
import ImportCSV from "./pages/ImportCSV";
import Automations from "./pages/Automations";
import Team from "./pages/Team";
import AppsPage from "./pages/AppsPage";
import RegisterAppPage from "./pages/RegisterAppPage";
import TeamMembers from "./pages/TeamMembers";
import { RecoilRoot } from "recoil";
import Wiki from "./pages/Wiki";
import WikiNew from "./pages/WikiNew";
import ApiDocumentation from "./pages/ApiDocumentation";
import AgentsPage from "./pages/AgentsPage";
import CreateAgentPage from "./pages/CreateAgentPage";
import ManageAgentPage from "./pages/ManageAgentPage";
import AgentTeamsPage from "./pages/AgentTeamsPage";
import AgentTeamPage from "./pages/AgentTeamPage";
import ScheduledTasksPage from "./pages/ScheduledTasksPage";
import ActivityFeed from "./pages/ActivtyFeed";

const { Header, Sider, Content } = Layout;

function App() {
  const [objectTypes, setObjectTypes] = useState();
  const [chatVisible, setChatVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(false); // New state for sidebar collapse

  // const handleTeamCreated = (teamId) => {
  //   localStorage.setItem("teamId", teamId);
  //   setTeamId(teamId);
  // };

  // const handleTeamJoined = (teamId) => {
  //   localStorage.setItem("teamId", teamId);
  //   setTeamId(teamId);
  // };

  useEffect(() => {
    const teamId = localStorage.getItem("teamId");
    if (teamId) {
      axios
        .get(process.env.REACT_APP_API_URL + `/object-types?teamId=${teamId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setObjectTypes(response.data.data);
        })
        .catch((error) => {
          console.error("There was an error fetching the object types!", error);
          if (error?.response?.status === 401) {
            if (localStorage.getItem("token")) {
            }
          }
        });
    }
  }, []);

  const signOut = () => {
    localStorage.removeItem("token");
    console.log("Sign out successful!");
  };

  const userMenu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          window.location.href = "/profile";
        }}
      >
        Profile
      </Menu.Item>
      {/* <Menu.Item key="2">Settings</Menu.Item> */}
      <Menu.Item
        key="3"
        onClick={() => {
          signOut();
          window.location.href = "/signin";
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  function isMobile() {
    return window.innerWidth <= 768;
  }

  return (
    <React.StrictMode>
      <GoogleAuthApp>
        <RecoilRoot>
          <Router>
            <Layout
              style={{
                height: "100vh ",
                overflow: "hidden",
              }}
            >
              {isMobile() && (
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 999,
                    backgroundColor: "white",
                    fontSize: 44,
                    color: "#555",
                    textAlign: "center",
                    paddingTop: "20%",
                  }}
                >
                  Sorry, no mobile support yet.
                </div>
              )}
              {collapsed ? (
                <Button
                  onClick={() => setCollapsed(false)}
                  style={{ position: "absolute", left: 10, top: 20, zIndex: 1 }}
                >
                  <MenuOutlined />
                </Button>
              ) : (
                <Sider
                  className="sidebar"
                  theme="light"
                  width={isMobile() ? "0%" : 260}
                  collapsible // Make the sidebar collapsible
                  collapsed={collapsed} // Bind the collapsed state
                  onCollapse={(value) => setCollapsed(value)} // Update state on collapse
                  style={{
                    backgroundColor: "#2D2D2D",
                  }}
                >
                  <SidebarMenu />
                </Sider>
              )}

              <Layout className="site-layout">
                <Header
                  className="site-layout-background"
                  style={{
                    padding: 0,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "0px solid #e8e8e8",
                    height: "44px",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <div style={{ flex: 1 }}></div>
                  <Space style={{ marginRight: "16px" }}>
                    <Dropdown overlay={userMenu}>
                      <Button icon={<SettingOutlined />} />
                    </Dropdown>
                  </Space>
                </Header>
                <Content
                  style={{
                    margin: "4px",
                    padding: "4px",
                    borderTopLeftRadius: 8,
                    overflowY: "auto",
                    height: "calc(100vh - 64px)", // Subtract the header height
                  }}
                >
                  <Routes>
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/automations" element={<Automations />} />
                    <Route
                      path="/apps"
                      element={
                        <ProtectedRoute component={() => <AppsPage />} />
                      }
                    />
                    <Route
                      path="/apps-register"
                      element={
                        <ProtectedRoute component={() => <RegisterAppPage />} />
                      }
                    />
                    <Route
                      path="/import-csv"
                      element={
                        <ProtectedRoute component={() => <ImportCSV />} />
                      }
                    />
                    {localStorage.getItem("teamId") && (
                      <Route
                        path="/"
                        element={
                          <Navigate
                            replace
                            to={`/team-members/${localStorage.getItem(
                              "teamId"
                            )}`}
                          />
                        }
                      />
                    )}

                    {!localStorage.getItem("teamId") && (
                      <Route
                        path="/"
                        element={<Navigate replace to="/create-team" />}
                      />
                    )}

                    <Route
                      path="/create-team"
                      element={
                        <ProtectedRoute component={() => <CreateTeam />} />
                      }
                    />
                    <Route
                      path="/join-team"
                      element={
                        <ProtectedRoute component={() => <JoinTeam />} />
                      }
                    />

                    <Route
                      path="/agents"
                      element={
                        <ProtectedRoute component={() => <AgentsPage />} />
                      }
                    />

                    <Route
                      path="/agents/create"
                      element={
                        <ProtectedRoute component={() => <CreateAgentPage />} />
                      }
                    />

                    <Route
                      path="/agents/manage/:agentId"
                      element={
                        <ProtectedRoute component={() => <ManageAgentPage />} />
                      }
                    />

                    <Route
                      path="/team/:teamId"
                      element={<ProtectedRoute component={() => <Team />} />}
                    />

                    <Route
                      path="/team-members/:teamId"
                      element={
                        <ProtectedRoute component={() => <TeamMembers />} />
                      }
                    />

                    <Route
                      path="agents/teams/:agentTeamId"
                      element={<AgentTeamPage />}
                    />

                    {objectTypes &&
                      objectTypes.map((type) => (
                        <Route
                          key={type?.name}
                          path={`/${type?.name}`}
                          element={
                            <ProtectedRoute
                              component={() => (
                                <Things objectType={type?.name} />
                              )}
                            />
                          }
                        />
                      ))}

                    {objectTypes &&
                      objectTypes.map((type) => (
                        <Route
                          key={type?.name}
                          path={`/${type?.name}/:id`}
                          element={
                            <ProtectedRoute
                              component={() => (
                                <Thing objectType={type?.name} />
                              )}
                            />
                          }
                        />
                      ))}
                    <Route
                      path="/wiki/:wikiId"
                      element={<ProtectedRoute component={() => <Wiki />} />}
                    />
                    <Route
                      path="/wiki-new"
                      element={<ProtectedRoute component={() => <WikiNew />} />}
                    />
                    <Route
                      path="/activity-feed/:teamId"
                      element={
                        <ProtectedRoute component={() => <ActivityFeed />} />
                      }
                    />
                    <Route
                      path="/add-object-type"
                      element={
                        <ProtectedRoute component={() => <AddObjectType />} />
                      }
                    />
                    <Route
                      path="/api-docs"
                      element={
                        <ProtectedRoute
                          component={() => <ApiDocumentation />}
                        />
                      }
                    />

                    <Route
                      path="/scheduled"
                      element={
                        <ProtectedRoute
                          component={() => <ScheduledTasksPage />}
                        />
                      }
                    />
                    <Route
                      path="/edit-object-type/:objectType"
                      element={
                        <ProtectedRoute component={() => <EditObjectType />} />
                      }
                    />

                    <Route
                      path="/profile"
                      element={
                        <ProtectedRoute component={() => <UserProfile />} />
                      }
                    />
                  </Routes>
                </Content>
              </Layout>
            </Layout>
          </Router>
        </RecoilRoot>
      </GoogleAuthApp>
    </React.StrictMode>
  );
}

export default App;
