import React, { useState, useEffect } from "react";
import { Form, Input, Button, List, Avatar, notification } from "antd";
import axios from "axios";
import { useParams } from "react-router-dom";
import { profileState, teamMembersState, teamState } from "../state";
import { useRecoilState } from "recoil";

const TeamMembers = () => {
  const [members, setMembers] = useRecoilState(teamMembersState);
  const [loading, setLoading] = useState(false);
  const [inviting, setInviting] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [team, setTeam] = useRecoilState(teamState);
  const [profile, setProfile] = useRecoilState(profileState);
  const [editingName, setEditingName] = useState(false);
  const [newTeamName, setNewTeamName] = useState("");

  const { teamId } = useParams();

  const handleInvite = async () => {
    if (!inviteEmail) {
      return;
    }

    setInviting(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/invite?teamId=" + teamId,

        { email: inviteEmail },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      notification.success({
        message: "Success",
        description: `Invite sent to ${inviteEmail}`,
      });
      setInviteEmail("");
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to send invite",
      });
    } finally {
      setInviting(false);
    }
  };

  const handelLeaveTeam = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL +
          "/teams/" +
          teamId +
          "/leave?teamId=" +
          teamId,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      localStorage.removeItem("teamId");
      window.location.href = "/";
      notification.success({
        message: "Success",
        description: `You have left the team`,
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to leave team",
      });
    }
  };

  const handelRemoveMember = async (member) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL +
          "/teams/" +
          teamId +
          "/remove?teamId=" +
          teamId,
        { userId: member.userId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      notification.success({
        message: "Success",
        description: `Removed ${member.name} from the team`,
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to remove member",
      });
    }
  };

  const handelDeleteTeam = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL +
          "/teams/" +
          teamId +
          "/delete?teamId=" +
          teamId,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      notification.success({
        message: "Success",
        description: `Team deleted`,
      });
      localStorage.removeItem("teamId");
      window.location.href = "/";
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to delete team",
      });
    }
  };

  return (
    <div
      style={{
        padding: "32px 40px",
        maxWidth: "1200px",
        margin: "0 auto",
      }}
    >
      {editingName ? (
        <div
          style={{
            display: "flex",
            gap: 8,
            alignItems: "center",
            marginBottom: 16,
          }}
        >
          <Input
            value={newTeamName}
            onChange={(e) => setNewTeamName(e.target.value)}
            style={{
              width: 300,
              height: "42px",
              fontSize: "24px",
              border: "none",
              padding: "0px",
              boxShadow: "none",
              borderBottom: "2px solid #1890ff",
              borderRadius: 0,
              marginTop: -4,
            }}
            autoFocus
          />
          <Button
            type="primary"
            onClick={async () => {
              try {
                await axios.put(
                  `${process.env.REACT_APP_API_URL}/teams/${teamId}/name?teamId=${teamId}`,
                  { name: newTeamName },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  }
                );
                setTeam({ ...team, name: newTeamName });
                setEditingName(false);
                notification.success({
                  message: "Team name updated successfully",
                });
              } catch (error) {
                notification.error({
                  message: "Failed to update team name",
                  description: error.message,
                });
              }
            }}
          >
            Save
          </Button>
          <Button onClick={() => setEditingName(false)}>Cancel</Button>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            gap: 8,
            alignItems: "center",
            marginBottom: 16,
          }}
        >
          <h1
            style={{
              margin: 0,
              cursor: team?.role === "owner" ? "pointer" : "default",
              padding: "4px 8px",
              borderRadius: "4px",
              transition: "background-color 0.3s",
              ":hover": {
                backgroundColor:
                  team?.role === "owner" ? "#f0f0f0" : "transparent",
              },
            }}
            onClick={() => {
              if (team?.role === "owner") {
                setNewTeamName(team?.name || "");
                setEditingName(true);
              }
            }}
          >
            {team?.name}
            {team?.role === "owner" && (
              <Button
                type="text"
                size="small"
                style={{
                  marginLeft: "8px",
                  opacity: 0.6,
                }}
              >
                ✎
              </Button>
            )}
          </h1>
        </div>
      )}
      <div
        style={{
          background: "white",
          padding: "24px",
          borderRadius: "12px",
          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
          marginTop: "24px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "24px",
          }}
        >
          <h2 style={{ margin: 0 }}>Team Members ({members?.length || 0})</h2>
        </div>
        <List
          itemLayout="horizontal"
          dataSource={members}
          loading={loading}
          style={{
            backgroundColor: "white",
          }}
          renderItem={(member) => (
            <List.Item
              style={{
                padding: "16px",
                borderRadius: "8px",
                transition: "background-color 0.3s",
                ":hover": {
                  backgroundColor: "#f5f5f5",
                },
              }}
              actions={
                team &&
                team.role &&
                team.role == "owner" &&
                member.userId !== profile.userId
                  ? [
                      <Button
                        type="text"
                        danger
                        onClick={() => {
                          handelRemoveMember(member);
                        }}
                      >
                        Remove
                      </Button>,
                    ]
                  : [
                      member.userId === profile.userId &&
                      team.role !== "owner" ? (
                        <Button
                          type="text"
                          danger
                          onClick={() => {
                            handelLeaveTeam();
                          }}
                        >
                          Leave
                        </Button>
                      ) : null,
                    ]
              }
            >
              <List.Item.Meta
                avatar={<Avatar src={member.picture} />}
                title={member.name}
                description={member.email}
              />
            </List.Item>
          )}
        />

        <div
          style={{
            marginTop: 24,
            padding: "16px",
            background: "#f5f5f5",
            borderRadius: "8px",
            maxWidth: "500px",
          }}
        >
          <h3 style={{ marginBottom: 16 }}>Invite Team Member</h3>
          <Form layout="horizontal" onFinish={handleInvite}>
            <div
              style={{
                display: "flex",
                gap: "8px",
              }}
            >
              <Input
                placeholder="Enter email address"
                value={inviteEmail}
                onChange={(e) => setInviteEmail(e.target.value)}
                style={{
                  height: "40px",
                  borderRadius: "6px",
                }}
                prefix={<span style={{ color: "#bfbfbf" }}>✉</span>}
              />
              <Button
                type="primary"
                htmlType="submit"
                loading={inviting}
                onClick={handleInvite}
                style={{
                  height: "40px",
                  borderRadius: "6px",
                  minWidth: "100px",
                }}
              >
                Invite
              </Button>
            </div>
          </Form>
        </div>

        {team && team.role && team.role == "owner" && (
          <div
            style={{
              marginTop: "48px",
              padding: "24px",
              background: "#fff1f0",
              borderRadius: "12px",
              border: "1px solid #ffccc7",
            }}
          >
            <h3 style={{ color: "#cf1322", marginBottom: "16px" }}>
              Danger Zone
            </h3>
            <p style={{ color: "#666", marginBottom: "16px" }}>
              Once you delete a team, there is no going back. Please be certain.
            </p>
            <Button
              danger
              type="primary"
              onClick={() => {
                handelDeleteTeam();
              }}
            >
              Delete Team
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamMembers;
