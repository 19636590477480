import React from "react";
import { Button, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { Tag } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";
import "../pages/Things.css"; // This can be moved to a more appropriate location
import _ from "lodash";

const DataTable = ({
  fields,
  data,
  rowSelection,
  objectType,
  relatedObjects,
  objectTypes,
  visibleColumns,
}) => {
  const navigate = useNavigate();

  function convertIcon(icon) {
    const IconComponent = Icons[icon];
    return IconComponent ? <IconComponent /> : <Icons.BarsOutlined />;
  }

  const handleTableChange = (pagination, filters, sorter) => {
    console.log("pagination", pagination);
    console.log("filters", filters);
    console.log("sorter", sorter);
  };

  const columns = fields.map((field) => ({
    title:
      field.label || field.name.charAt(0).toUpperCase() + field.name.slice(1),
    dataIndex: field.name,
    key: field.name,

    filterMode: "tree",
    filterSearch: true,
    filters:
      field.type == "tags" &&
      _.uniq(data.map((record) => record[field.name])).map((value) => ({
        text: value,
        value,
      })),

    onFilter: (value, record) => record[field.name] === value,
    hidden: visibleColumns && !visibleColumns.includes(field.name),
    render: (text, record) => {
      if (field.type === "tags") {
        return (
          <>
            {text &&
              typeof text === "string" &&
              text.split(",").map((tag) => (
                <Tag color="blue" key={tag}>
                  {tag}
                </Tag>
              ))}

            {text &&
              typeof text === "object" &&
              text.map((tag) => (
                <Tag color="blue" key={tag}>
                  {tag}
                </Tag>
              ))}
          </>
        );
      }
      if (field.type === "date") {
        return text && new Date(text).toLocaleDateString();
      }
      if (field.type === "file" && text) {
        return (
          <a href={text} download>
            <Button icon={<DownloadOutlined />}>Download File</Button>
          </a>
        );
      }
      if (field.type === "relationship" && text) {
        const relatedObject = relatedObjects[field.relatedObjectType]?.find(
          (relatedObject) => relatedObject.id === text
        );

        const relatedObjectType = objectTypes?.find(
          (objectType) => objectType.id === field.relatedObjectType
        );

        if (relatedObject && relatedObject.id) {
          return (
            <a
              href={`/${relatedObjectType.name}/${text}`}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/${relatedObjectType.name}/${text}`);
              }}
            >
              <Button size="small">
                {relatedObjectType &&
                  relatedObjectType.icon &&
                  convertIcon(relatedObjectType.icon)}
                {relatedObject &&
                  (relatedObject.name ||
                    relatedObject.Name ||
                    relatedObject.title ||
                    relatedObject.Title ||
                    relatedObject.firstName ||
                    relatedObject.FirstName ||
                    relatedObject.lastName ||
                    relatedObject.LastName ||
                    relatedObject.email ||
                    relatedObject.Email ||
                    relatedObject.description ||
                    relatedObject.Description ||
                    relatedObject.subject ||
                    relatedObject.Subject ||
                    text)}
              </Button>
            </a>
          );
        } else {
          return <div>Not Found</div>;
        }
      }
      return (
        <div
          style={{
            cursor: "pointer",
            maxHeight: 60,
            minWidth: 120,
            overflow: "hidden",
          }}
        >
          {text}
        </div>
      );
    },
  }));

  return (
    <Table
      rowSelection={rowSelection}
      columns={columns}
      dataSource={data}
      rowKey={(record) => record.id}
      size="small"
      style={{ cursor: "pointer" }}
      onChange={handleTableChange}
      onRow={(record) => ({
        onClick: () => {
          console.log("record", record);
          navigate(`/${objectType}/${record.id}`);
        },
      })}
    />
  );
};

export default DataTable;
