import React, { useState, useEffect } from "react";
import {
  List,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Badge,
  Avatar,
  message,
} from "antd";
import axios from "axios";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import _ from "lodash";
import TaskBoard from "./TaskBoard";
import {
  MenuOutlined,
  ProjectOutlined,
  ProjectFilled,
  EditFilled,
} from "@ant-design/icons";

const { Option } = Select;

const TodoList = ({
  thingId,
  agents,
  users,
  onTaskSelect,
  selectedTaskId,
  updated,
  showBoard,
  setShowBoard,
}) => {
  const [tasks, setTasks] = useState([]);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [selectedTask, setSelectedTask] = useState(null);

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/tasks?thingId=${thingId}&teamId=${localStorage.getItem("teamId")}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setTasks(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching tasks:", error);
      });
  };

  const handleAddTask = () => {
    form.resetFields();
    setSelectedTask(null);
    setVisible(true);
  };

  const handleEditTask = (task) => {
    form.setFieldsValue(task);
    setSelectedTask(task);
    setVisible(true);
  };

  const handleDeleteTask = (taskId) => {
    setVisible(false);
    axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/tasks/${taskId}?teamId=${localStorage.getItem("teamId")}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        fetchTasks();
      })
      .catch((error) => {
        console.error("Error deleting task:", error);
      });
  };

  const handleAIRequest = async (agentId, messageBody, task) => {
    try {
      const requestData = {
        objectId: thingId,
        messages: [messageBody],
        taskId: task.taskId,
        taskHumanReadableId: task.humanReadableId,
      };

      await axios.post(
        `${
          process.env.REACT_APP_API_URL
        }/agent/${agentId}/interact?teamId=${localStorage.getItem("teamId")}`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      message.success("OpenAI response added to activity log.");
      updated();
    } catch (error) {
      console.error("There was an error querying OpenAI!", error);
      message.error("Error querying OpenAI.");
    }
  };

  function assignedToUser(task) {
    if (task.assignedTo.startsWith("user:")) {
      return users.find((user) => `user:${user.userId}` === task.assignedTo);
    } else {
      return agents.find(
        (agent) => `agent:${agent.agentId}` === task.assignedTo
      );
    }
  }

  const handleFormSubmit = (values) => {
    const taskData = { ...values, thingId };
    if (selectedTask) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/tasks/${
            selectedTask.taskId
          }?teamId=${localStorage.getItem("teamId")}`,
          taskData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then(() => {
          setVisible(false);
          fetchTasks();
        })
        .catch((error) => {
          console.error("Error updating task:", error);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/tasks?teamId=${localStorage.getItem(
            "teamId"
          )}`,
          taskData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((newTask) => {
          onTaskSelect(newTask.data.data);
          setVisible(false);
          if (values.assignedTo.startsWith("agent:")) {
            handleAIRequest(
              values.assignedTo.split(":")[1],
              "Here is a new task for you:  \n\n" + values.title,
              newTask.data.data
            );
          }
          fetchTasks();
        })
        .catch((error) => {
          console.error("Error creating task:", error);
        });
    }
  };

  const handleOnDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    // If there is no valid destination, return early
    if (!destination) return;

    console.log("Source:", source);
    console.log("Destination:", destination);
    console.log("result Before Move:", result);

    // Create a deep copy of tasks array
    const updatedTasks = structuredClone(tasks);

    console.log("Tasks Before Move:", updatedTasks);

    console.log("Updated Task Order After Move:", updatedTasks);

    updatedTasks.forEach((task, index) => {
      if (task.taskId === draggableId) {
        task.order = index;
        task.status =
          destination.droppableId != "tasks"
            ? destination.droppableId
            : task.status;
      }
    });

    // Set the tasks with updated orders and statuses
    // setTasks(updatedTasks);

    // Prepare the tasks to be sent to the backend
    const newTasks = updatedTasks.map((task) => ({
      taskId: task.taskId,
      status: task.status,
      order: task.order,
    }));

    console.log("Tasks being sent to the backend:", newTasks);

    setTasks(updatedTasks);

    // Send the updated task order and status to the backend
    axios.put(
      `${
        process.env.REACT_APP_API_URL
      }/tasks/reorder?teamId=${localStorage.getItem("teamId")}`,
      { tasks: newTasks },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  };

  const getStatusBadge = (status) => {
    let color;
    if (status === "todo") color = "red";
    else if (status === "doing") color = "orange";
    else if (status === "done") color = "green";
    return <Badge color={color} />;
  };

  return (
    <div
      style={{
        width: "calc(100% - 20px)",
        marginLeft: 10,
      }}
    >
      <div
        style={{
          paddingLeft: 8,
          paddingRight: 8,
          marginBottom: 10,
          display: "flex",
          backgroundColor: "#fff",
          borderRadius: 12,
        }}
      >
        <div>
          <Button
            style={{
              margin: "10px",
            }}
            type="primary"
            onClick={handleAddTask}
          >
            Add Task
          </Button>
        </div>
      </div>

      {showBoard ? (
        <TaskBoard
          tasks={tasks}
          agents={agents}
          users={users}
          onTaskSelect={onTaskSelect}
          selectedTaskId={selectedTaskId}
          updated={updated}
          handleOnDragEnd={handleOnDragEnd}
          onTaskEdit={handleEditTask}
        />
      ) : (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="tasks">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  maxHeight: "calc(100vh - 200px)",
                  overflowY: "auto",
                }}
              >
                {_.orderBy(tasks, "order").map((task, index) => (
                  <Draggable
                    key={task.taskId}
                    draggableId={task.taskId.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            selectedTaskId === task.taskId
                              ? "#e6f7ff"
                              : "white",
                          padding: 8,
                          borderRadius: 12,
                          position: "relative",

                          marginBottom: 8,
                          ...provided.draggableProps.style,
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: 4,
                            right: 4,
                            border: "1px solid #ccc",
                            padding: "2px 4px",
                            borderRadius: 4,
                            boxShadow: "0 0 2px #ccc",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditTask(task);
                          }}
                        >
                          <EditFilled />
                        </div>
                        <List.Item.Meta
                          title={task.title}
                          onClick={() =>
                            onTaskSelect(
                              task.taskId === selectedTaskId ? null : task
                            )
                          }
                          description={
                            <div style={{ position: "relative" }}>
                              <div
                                style={{
                                  fontSize: 12,
                                  fontWeight: 600,
                                  marginTop: -8,
                                  marginBottom: 8,
                                }}
                              >
                                {task &&
                                  task.status &&
                                  getStatusBadge(task.status)}{" "}
                                {task.humanReadableId}
                              </div>
                              <div
                                style={{
                                  fontWeight: 900,
                                  paddingRight: 8,
                                  fontSize: 12,
                                  color: "#555",
                                }}
                              >
                                <Avatar
                                  src={
                                    assignedToUser(task)?.picture ||
                                    assignedToUser(task)?.avatar
                                  }
                                  style={{
                                    marginRight: 8,
                                    backgroundColor: "#fff",
                                  }}
                                  size={24}
                                />
                                {assignedToUser(task)?.name}
                              </div>
                            </div>
                          }
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}

      <Modal
        title={selectedTask ? "Edit Task" : "Add Task"}
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => form.submit()}
      >
        <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
          <Form.Item
            name="title"
            label="Task Title"
            rules={[{ required: true, message: "Please enter the task title" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="assignedTo"
            label="Assign To"
            rules={[
              { required: true, message: "Please select a user or agent" },
            ]}
          >
            <Select
              showSearch
              placeholder="Select user or AI agent"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {users.map((user) => (
                <Option
                  key={`user:${user.userId}`}
                  value={`user:${user.userId}`}
                >
                  {user?.name}
                </Option>
              ))}
              {agents.map((agent) => (
                <Option
                  key={`agent:${agent.agentId}`}
                  value={`agent:${agent.agentId}`}
                >
                  {agent?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            rules={[{ message: "Please provide more details" }]}
            name="moreDetails"
            label="More Details For Agent"
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            name="status"
            label="Status"
            rules={[
              { required: true, message: "Please select the task status" },
            ]}
          >
            <Select>
              <Option value="todo">Todo</Option>
              <Option value="doing">Doing</Option>
              <Option value="done">Done</Option>
            </Select>
          </Form.Item>
          {selectedTask && (
            <Button
              type="default"
              danger
              onClick={() => handleDeleteTask(selectedTask.taskId)}
            >
              Delete Task
            </Button>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default TodoList;
