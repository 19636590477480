import React from "react";
import { Button, Popconfirm } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const ActionButtons = ({
  objectType,
  selectedRowKeys,
  onDeleteConfirm,
  onDeleteCancel,
}) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row-reverse",
        gap: 10,
        paddingRight: 10,
      }}
    >
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => navigate(`/${objectType}/new`)}
      >
        Add {objectType}
      </Button>
      <Button
        type="default"
        icon={<EditOutlined />}
        onClick={() => navigate(`/edit-object-type/${objectType}`)}
      >
        Edit {objectType}
      </Button>
      <Popconfirm
        title={`Are you sure you want to delete ${selectedRowKeys.length} items?`}
        onConfirm={onDeleteConfirm}
        onCancel={onDeleteCancel}
        okText="Yes"
        cancelText="No"
      >
        <Button
          disabled={selectedRowKeys.length === 0}
          type="default"
          icon={<DeleteOutlined />}
          className={`delete-button ${selectedRowKeys.length > 0 ? 'has-selected' : ''}`}
        >
          Delete Selected
        </Button>
      </Popconfirm>
    </div>
  );
};

export default ActionButtons;
