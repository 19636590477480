import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu } from "antd";
import * as Icons from "@ant-design/icons";
import axios from "axios";
import "./SidebarMenu.css";
import {
  SettingOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import { useRecoilState } from "recoil";
import {
  objectTypesState,
  profileState,
  teamIdState,
  teamMembersState,
  teamsState,
  teamState,
  wikisState,
} from "../state";

const SidebarMenu = () => {
  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];
  const [teamMembers, setTeamMembers] = useRecoilState(teamMembersState);
  const [objectTypes, setObjectTypes] = useRecoilState(objectTypesState);
  const [teamId, setTeamId] = useRecoilState(teamIdState);
  const [profile, setProfile] = useRecoilState(profileState);
  const [teams, setTeams] = useRecoilState(teamsState);
  const [team, setTeam] = useRecoilState(teamState);
  const [wikis, setWikis] = useRecoilState(wikisState);

  const navigate = useNavigate();

  const currentTeamId = teamId || localStorage.getItem("teamId");

  useEffect(() => {
    const fetchInitialData = async () => {
      const storedTeamId = localStorage.getItem("teamId");
      const token = localStorage.getItem("token");

      if (storedTeamId && token) {
        setTeamId(storedTeamId);

        try {
          // Fetch object types, teams, team members, profile, and wikis concurrently
          const [
            objectTypesRes,
            teamsRes,
            teamMembersRes,
            profileRes,
            wikisRes,
          ] = await Promise.all([
            axios.get(
              `${process.env.REACT_APP_API_URL}/object-types?teamId=${storedTeamId}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            ),
            axios.get(`${process.env.REACT_APP_API_URL}/teams`, {
              headers: { Authorization: `Bearer ${token}` },
            }),
            axios.get(
              `${process.env.REACT_APP_API_URL}/teams/${storedTeamId}/members`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            ),
            axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
              headers: { Authorization: `Bearer ${token}` },
            }),
            axios.get(
              `${process.env.REACT_APP_API_URL}/wikis/team/${storedTeamId}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            ),
          ]);

          // Save data to state and localStorage

          setObjectTypes(objectTypesRes.data.data || []); // Fallback to empty array
          localStorage.setItem(
            "objectTypes",
            JSON.stringify(objectTypesRes.data.data || [])
          );

          setTeams(teamsRes.data || []); // Fallback to empty array
          localStorage.setItem("teams", JSON.stringify(teamsRes.data || []));

          setTeam(teamsRes.data.find((t) => t.teamId === storedTeamId) || null); // Fallback to null
          setTeamMembers(teamMembersRes.data.members || []); // Fallback to empty array

          setProfile(profileRes.data.data || {}); // Fallback to empty object
          localStorage.setItem(
            "profile",
            JSON.stringify(profileRes.data.data || {})
          );

          setWikis(wikisRes.data.wikis || []); // Fallback to empty array
          localStorage.setItem(
            "wikis",
            JSON.stringify(wikisRes.data.wikis || [])
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    // event listener to update profile state when profile is updated
    window.addEventListener("update-wiki-title", (e) => {
      setWikis(
        wikis.map((wiki) => {
          if (wiki.wikiId === e.detail.wikiId) {
            return {
              ...wiki,
              title: e.detail.title,
            };
          }
          return wiki;
        })
      );
    });

    fetchInitialData();
  }, []);

  function convertIcon(icon) {
    const IconComponent = Icons[icon];
    return IconComponent ? <IconComponent /> : <Icons.BarsOutlined />;
  }

  return (
    <>
      {profile && profile.name && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px",
              color: "white",
              cursor: "pointer",
              backgroundColor: "#ffffff22",
              borderRadius: "8px",
              margin: "10px",
            }}
            onClick={() => navigate("/profile")}
          >
            <div
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "gray",
                marginRight: "10px",
                backgroundImage: `url(${profile?.picture})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{ color: "white", fontSize: "14px", fontWeight: "bold" }}
              >
                {profile?.name || "User Name"}
              </div>
              <div style={{ color: "white", fontSize: "12px" }}>
                {profile?.email || "Email"}
              </div>
            </div>
          </div>
          <Menu
            style={{
              height: "calc(100vh - 100px)",
              borderRight: 0,
              backgroundColor: "#00000000",
              padding: "0 10px",
            }}
            mode="inline"
            selectedKeys={[
              currentPath,
              `team:${currentTeamId}`,
              `wiki:${location.pathname.split("/")[2]}`,
            ]}
            defaultOpenKeys={[
              "teams",
              "object-types",
              "apps",
              "pages",
              "wikis",
            ]}
            onClick={(e) => {
              const [type, id] = e.key.split(":");
              if (type === "team-pending") {
                navigate(`/create-team`);
              }
              if (type === "team") {
                if (id != localStorage.getItem("teamId")) {
                  localStorage.setItem("teamId", id);
                  window.location.href = "/team-members/" + id;
                } else {
                  navigate(`/team-members/${id}`);
                }
              } else if (type === "wiki") {
                if (id === "new") {
                  navigate("/wiki-new");
                } else {
                  navigate(`/wiki/${id}`);
                }
              }
            }}
          >
            <>
              <Menu.SubMenu key="teams" title="Teams">
                {teams &&
                  teams.map((team) => {
                    if (team.role == "pending") {
                      return (
                        <Menu.Item
                          key={`team-pending:${team.teamId}`}
                          icon={<Icons.TeamOutlined />}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              fontStyle: "italic",
                            }}
                          >
                            <span>{team.name}</span>
                            {team.teamId === currentTeamId && (
                              <div>
                                {/* <CheckCircleFilled
                                  style={{
                                    color: "#B2FFB2",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  
                                  }}
                                />
                                <CloseCircleFilled
                                  style={{
                                    color: "#FF9999",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(`/team-members/${team.teamId}`);
                                  }}
                                /> */}
                              </div>
                            )}
                          </div>
                        </Menu.Item>
                      );
                    } else {
                      return (
                        <Menu.Item
                          key={`team:${team.teamId}`}
                          icon={<Icons.TeamOutlined />}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>{team.name}</span>
                            {team.teamId === currentTeamId && (
                              <SettingOutlined
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigate(`/team-members/${team.teamId}`);
                                }}
                              />
                            )}
                          </div>
                        </Menu.Item>
                      );
                    }
                  })}
                <Menu.Item
                  key="create-team"
                  icon={<Icons.PlusCircleOutlined />}
                >
                  <Link to="/create-team">Create Team</Link>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu key="object-types" title="Spaces">
                {objectTypes &&
                  objectTypes.map((objectType) => (
                    <Menu.Item
                      key={objectType.name}
                      icon={convertIcon(objectType.icon)}
                    >
                      <Link to={`/${objectType.name}`}>
                        {objectType.name.charAt(0).toUpperCase() +
                          objectType.name.slice(1)}
                      </Link>
                    </Menu.Item>
                  ))}
                <Menu.Item
                  key="add-object-type"
                  icon={<Icons.PlusCircleOutlined />}
                >
                  <Link to="/edit-object-type/new">Add Space</Link>
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.SubMenu key="apps" title="Apps">
                <Menu.Item key="automations" icon={<Icons.SettingOutlined />}>
                  <Link to="/automations">Configure</Link>
                </Menu.Item>
                {team && team.openaiApiKey && (
                  <>
                    <Menu.Item key="agents" icon={<Icons.RobotOutlined />}>
                      <Link to="/agents">Agents</Link>
                    </Menu.Item>
                    <Menu.Item
                      key="scheduled"
                      icon={<Icons.ClockCircleOutlined />}
                    >
                      <Link to="/scheduled">Schedules</Link>
                    </Menu.Item>
                  </>
                )}
                <Menu.Item
                  key="activity-feed"
                  icon={<Icons.FileTextOutlined />}
                >
                  <Link to={"/activity-feed/" + teamId}>Activity Feed</Link>
                </Menu.Item>
              </Menu.SubMenu>
            </>
          </Menu>
        </>
      )}
    </>
  );
};

export default SidebarMenu;
