import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Space,
  Typography,
  Upload,
  Skeleton,
  Modal,
  Tag,
} from "antd";
import {
  UploadOutlined,
  ExclamationCircleOutlined,
  DownloadOutlined,
  CaretRightOutlined,
  CaretDownOutlined,
  MessageOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import ActivityLog from "../components/ActivityChat";
import "./Thing.css";
import { useRecoilState } from "recoil";
import TodoList from "../components/TodoList";
import ViewSelector from "../components/ViewSelector";
import {
  objectTypesState,
  objectTypeState,
  teamIdState,
  teamMembersState,
  teamState,
} from "../state";
import FileManager from "../components/FileManager";

const { Option } = Select;
const { Title } = Typography;
const { confirm } = Modal;
// const { Panel } = Collapse;

const Thing = () => {
  const { id } = useParams();
  const objectType = window.location.pathname.split("/")[1];
  const navigate = useNavigate();
  const [isDetailsCollapsed, setIsDetailsCollapsed] = useState(false);
  const [isTaskCollapsed, setIsTaskCollapsed] = useState(false);

  const toggleDetailsCollapse = () =>
    setIsDetailsCollapsed(!isDetailsCollapsed);
  const toggleTaskCollapse = () => setIsTaskCollapsed(!isTaskCollapsed);
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [type, setType] = useState();
  const [activityLog, setActivityLog] = useState([]);
  const [relatedObjects, setRelatedObjects] = useState({});
  const [loading, setLoading] = useState(true);
  const [agents, setAgents] = useState([]);
  const [agentTeams, setAgentTeams] = useState([]);
  const [showBoard, setShowBoard] = useState(
    localStorage.getItem("showBoard-" + objectType) === "true"
  );

  const [teamMembers, setTeamMembers] = useRecoilState(teamMembersState);
  const [teamId, setTeamId] = useRecoilState(teamIdState);
  const [team, setTeam] = useRecoilState(teamState);
  const [showFileManager, setShowFileManager] = useState(true);

  // States for collapsing
  const [currentView, setCurrentView] = useState(
    localStorage.getItem("preferredView-" + objectType) || "details"
  );

  const handleViewChange = (view) => {
    setCurrentView(view);
    localStorage.setItem("preferredView-" + objectType, view);
  };

  // Inside your Thing component
  const [selectedTask, setSelectedTask] = useState(null);

  const handleTaskSelect = (task) => {
    setSelectedTask(task);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/object-types?teamId=${teamId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log("objectType", objectType);
        const objectTypeData = response.data.data.find(
          (item) => item.name === objectType
        );
        if (objectTypeData) {
          setType(objectTypeData);
          setFields(objectTypeData.fields);
          fetchRelatedObjects(objectTypeData.fields);
          if (teamId) {
            axios
              .get(`${process.env.REACT_APP_API_URL}/agents?teamId=${teamId}`, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              })
              .then((response) => {
                setAgents(response.data.agents);
                console.log("agents", response.data.agents);
              })
              .catch((error) => {
                console.error(
                  "There was an error fetching the team agents!",
                  error
                );
              });

            axios
              .get(
                `${process.env.REACT_APP_API_URL}/agents/teams?teamId=${teamId}`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              )
              .then((response) => {
                setAgentTeams(response.data.teams);
                console.log("agentTeams", response.data.teams);
              })
              .catch((error) => {
                console.error(
                  "There was an error fetching the team agents!",
                  error
                );
              });
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(
          "There was an error fetching the object type fields!",
          error
        );
      });

    if (id && id !== "new") {
      axios
        .get(`${process.env.REACT_APP_API_URL}/object/${id}?teamId=${teamId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          const data = response.data.data;
          // Convert date fields to moment objects
          const initialData = {
            ...data,
            ...Object.fromEntries(
              Object.entries(data).map(([key, value]) => [
                key,
                moment(value, moment.ISO_8601, true).isValid()
                  ? moment(value)
                  : value,
              ])
            ),
          };
          setLoading(false);
          setInitialValues(initialData);
          form.setFieldsValue(initialData);
          fetchActivityLog(id);
        })
        .catch((error) => {
          setLoading(false);
          console.error("There was an error fetching the object data!", error);
        });
    }
  }, []);

  useEffect(() => {
    // get team agents
  }, []);

  const fetchRelatedObjects = (fields) => {
    fields
      .filter((field) => field.type === "relationship")
      .forEach((field) => {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/objects/${field.relatedObjectType}?teamId=${teamId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            setRelatedObjects((prev) => ({
              ...prev,
              [field.name]: response.data.data,
            }));
            setLoading(false);
          })
          .catch((error) => {
            console.error(
              `There was an error fetching related objects for field ${field.name}!`,
              error
            );
          });
      });

    setLoading(false);
  };

  const fetchActivityLog = (objectId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/object/${objectId}/activity?teamId=${teamId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setActivityLog(response.data.data);
        // dispatch event
        //First, we initialize our event
        const event = new Event("onActivityLogUpdate");

        // Next, we dispatch the event.
        document.dispatchEvent(event);
      })
      .catch((error) => {
        console.error("There was an error fetching the activity log!", error);
      });
  };

  const handleSave = (values) => {
    // check if any of the value contain AWSAccessKeyId and remove it
    // this is a security measure to prevent the user from adding their own AWSAccessKeyId

    const formattedValues = {
      ...values,
      ...Object.fromEntries(
        Object.entries(values).map(([key, value]) => [
          key,
          moment.isMoment(value) ? value.toISOString() : value,
        ])
      ),
    };

    if (id && id !== "new") {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/object/${id}?teamId=${teamId}`,
          formattedValues,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then(() => {
          // navigate(`/${objectType}`);
          // window.location.reload();
        })
        .catch((error) => {
          console.error("There was an error updating the object!", error);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/object/${type.id}?teamId=${teamId}`,
          formattedValues,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then(() => {
          navigate(`/${objectType}`);
        })
        .catch((error) => {
          console.error("There was an error creating the object!", error);
        });
    }
  };

  const showDeleteConfirm = () => {
    confirm({
      title: "Are you sure delete this object?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone",
      onOk() {
        handleDelete();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleDelete = () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/object/${id}?teamId=${teamId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        navigate(`/${objectType}`);
      })
      .catch((error) => {
        console.error("There was an error deleting the object!", error);
      });
  };

  return (
    <div className="thing-page">
      <Title
        level={3}
        style={{
          textAlign: "left",
          position: "absolute",
          top: -16,
          left: 270,
          zIndex: 9999,
          display: "flex",
          justifyContent: "space-between",
          width: "calc(100vw - 360px)",
        }}
      >
        <div
          style={{
            paddingLeft: 24,
          }}
        >
          {initialValues.name ||
            initialValues.title ||
            initialValues.Name ||
            initialValues.Title ||
            initialValues.subject ||
            initialValues.Subject ||
            initialValues.fullName ||
            initialValues.FullName ||
            initialValues.firstName ||
            initialValues.FirstName ||
            initialValues.lastName ||
            initialValues.LastName ||
            initialValues.email ||
            initialValues.Email ||
            initialValues.description ||
            initialValues.Description ||
            initialValues.body ||
            initialValues.Body ||
            initialValues.content ||
            initialValues.Content ||
            "New " + objectType}
        </div>
        <ViewSelector
          currentView={currentView}
          onViewChange={handleViewChange}
        />
      </Title>
      {loading ? (
        <Skeleton active />
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              gap: "20px",
              height: "calc(100% - 60px)",
            }}
          >
            <div
              style={{
                flex: 1,
                display: currentView === "details" ? "flex" : "none",
                flexDirection: "row",
                gap: 20,
                width: "100%",
              }}
            >
              {/* Collapsible Details Box */}
              <div
                style={{
                  flex: 1,
                  display: currentView === "details" ? "block" : "flex",
                  transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
                  minWidth: "300px",
                  maxWidth: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  backgroundColor: "white",
                  padding: "20px",
                  paddingBottom: 80,
                  borderRadius: 12,
                  overflowY: "auto",
                  position: "relative",
                  flexDirection: "column",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.06)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: 18,
                      fontWeight: "bold",
                      marginBottom: 10,
                      paddingTop: 4,
                      display: isDetailsCollapsed ? "none" : "block",
                    }}
                    onClick={toggleDetailsCollapse}
                  >
                    {id === "new" ? `Add New ${objectType}` : "Details"}
                  </div>
                </div>
                <div
                  style={{
                    display: isDetailsCollapsed ? "none" : "block",
                  }}
                >
                  <Form
                    form={form}
                    layout="vertical"
                    initialValues={initialValues}
                    onFinish={handleSave}
                    onBlur={() => {
                      if (id && id !== "new") {
                        form.submit();
                      }
                    }}
                    className="thing-form"
                  >
                    {fields &&
                      fields.map((field) => (
                        <Form.Item
                          key={field.name}
                          name={field.name}
                          label={field.label}
                          style={{
                            marginBottom: 2,
                          }}
                          rules={[
                            {
                              required: field.required,
                              message: `Please enter the ${field.label.toLowerCase()}`,
                            },
                          ]}
                        >
                          {field.type === "text" && <Input />}
                          {field.type === "email" && <Input type="email" />}
                          {field.type === "yesno" && (
                            <Select placeholder="Add tags">
                              <Option key={"yes"} value={"yes"}>
                                Yes
                              </Option>
                              <Option key={"no"} value={"no"}>
                                No
                              </Option>
                            </Select>
                          )}
                          {field.type === "date" && (
                            <DatePicker style={{ width: "100%" }} />
                          )}
                          {field.type === "tags" && (
                            <Select mode="tags" placeholder="Add tags">
                              {[].map((tag) => (
                                <Option key={tag} value={tag}>
                                  {tag}
                                </Option>
                              ))}
                            </Select>
                          )}
                          {field.type === "textarea" && (
                            <Input.TextArea
                              autoSize={{ minRows: 3, maxRows: 10 }}
                            />
                          )}
                          {field.type === "file" && (
                            <div>
                              <Upload
                                name="file"
                                action={`${process.env.REACT_APP_API_URL}/upload`}
                                headers={{
                                  Authorization: `Bearer ${localStorage.getItem(
                                    "token"
                                  )}`,
                                }}
                                onChange={(info) => {
                                  if (info?.file?.status === "done") {
                                    form.setFieldsValue({
                                      [field.name]: info.file.response.url,
                                    });
                                  }
                                }}
                              >
                                <Button icon={<UploadOutlined />}>
                                  Click to upload
                                </Button>
                              </Upload>
                              {form.getFieldValue(field.name) && (
                                <div
                                  style={{
                                    marginTop: 10,

                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  {form
                                    .getFieldValue(field.name)
                                    .includes(".jpg") ? (
                                    <div
                                      style={{
                                        width: "100%",
                                        height: 100,
                                        borderRadius: 8,
                                        backgroundColor: "lightgray",
                                        backgroundImage: `url(${
                                          initialValues[field.name + "_signed"]
                                        })`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                      }}
                                      onClick={() => {
                                        window.open(
                                          initialValues[field.name + "_signed"],
                                          "_blank"
                                        );
                                      }}
                                    ></div>
                                  ) : (
                                    <DownloadOutlined />
                                  )}
                                  <a
                                    href={initialValues[field.name + "_signed"]}
                                    target="_blank"
                                  >
                                    Download file
                                  </a>
                                </div>
                              )}
                            </div>
                          )}
                          {field.type === "relationship" && (
                            <Select
                              showSearch
                              placeholder="Select related object"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            >
                              {relatedObjects[field.name]?.map(
                                (relatedObject) => {
                                  // Only show options for related objects that exist
                                  if (!relatedObject) return null;
                                  return (
                                    <Option
                                      key={relatedObject.id}
                                      value={relatedObject.id}
                                    >
                                      {relatedObject.name ||
                                        relatedObject.title ||
                                        relatedObject.Name ||
                                        relatedObject.Title ||
                                        relatedObject.firstName ||
                                        relatedObject.FirstName ||
                                        relatedObject.lastName ||
                                        relatedObject.LastName ||
                                        relatedObject.email ||
                                        relatedObject.Email ||
                                        relatedObject.subject ||
                                        relatedObject.Subject ||
                                        relatedObject.description ||
                                        relatedObject.Description ||
                                        relatedObject.body ||
                                        relatedObject.Body ||
                                        relatedObject.content ||
                                        relatedObject.Content}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          )}
                        </Form.Item>
                      ))}
                    <Form.Item>
                      {id === "new" ? (
                        <Space style={{ marginTop: 20 }}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => {
                              form.submit();
                              navigate(`/${objectType}`);
                            }}
                          >
                            Save
                          </Button>
                          <Button onClick={() => navigate(`/${objectType}`)}>
                            Cancel
                          </Button>
                        </Space>
                      ) : (
                        <Space style={{ marginTop: 20 }}>
                          <Button type="primary" htmlType="submit">
                            Save
                          </Button>
                          <Button danger onClick={showDeleteConfirm}>
                            Delete
                          </Button>
                        </Space>
                      )}
                    </Form.Item>
                  </Form>
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "white",
                  padding: 20,
                  borderRadius: 12,
                  overflowY: "auto",
                  position: "relative",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.06)",
                }}
              >
                {/* FileManager Component */}
                <FileManager
                  objectId={id}
                  teamId={teamId}
                  showFileManager={showFileManager}
                />
              </div>
            </div>
            {id && id !== "new" && (
              <>
                {/* Activity Box */}
                <div
                  style={{
                    flex: 1,
                    display: currentView === "chat" ? "flex" : "none",
                    position: "relative",
                    height: "calc(100vh - 130px)",
                  }}
                >
                  <div
                    style={{
                      flex: 3,
                    }}
                  >
                    <ActivityLog
                      objectId={id}
                      activityLog={activityLog}
                      fetchActivityLog={fetchActivityLog}
                      teamMembers={teamMembers}
                      users={teamMembers}
                      team={team}
                      agents={agents}
                      agentTeams={agentTeams}
                      selectedTask={selectedTask}
                      closeSelectedTask={() => setSelectedTask(null)}
                    />
                  </div>
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    <TodoList
                      thingId={id}
                      agents={agents}
                      users={teamMembers}
                      onTaskSelect={handleTaskSelect}
                      selectedTaskId={selectedTask ? selectedTask.taskId : null}
                      updated={() => fetchActivityLog(id)}
                      showBoard={false}
                      setShowBoard={(e) => {
                        setIsDetailsCollapsed(e);
                        setShowBoard(e);
                        localStorage.setItem("showBoard-" + objectType, e);
                      }}
                    />
                  </div>
                </div>
                {/* Task Box */}

                <div
                  style={{
                    flex: 1,
                    paddingTop: 30,
                    display: currentView === "board" ? "block" : "none",
                  }}
                >
                  <div
                    style={{
                      padding: 10,
                      zIndex: 100,
                      paddingLeft: 20,
                      marginBottom: -52,
                    }}
                  ></div>
                  <div
                    style={{
                      display: isTaskCollapsed ? "none" : "block",
                    }}
                  >
                    <TodoList
                      thingId={id}
                      agents={agents}
                      users={teamMembers}
                      onTaskSelect={handleTaskSelect}
                      selectedTaskId={selectedTask ? selectedTask.taskId : null}
                      updated={() => fetchActivityLog(id)}
                      showBoard={true}
                      setShowBoard={(e) => {
                        setIsDetailsCollapsed(e);
                        setShowBoard(e);
                        localStorage.setItem("showBoard-" + objectType, e);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Thing;
